import React from "react";
import styles from "./SectionTitle.module.scss";

export default function SectionTitle({
  title,
  headingTag,
  subtitle,
  highlightWords,
  color,
}) {
  const content = (
    <SectionTitleContent
      title={title}
      subtitle={subtitle}
      highlightWords={highlightWords}
      color={color}
    />
  );
  if (!headingTag) {
    return content;
  }

  const wrapper = React.createElement(
    headingTag === "none" ? "span" : headingTag,
    null,
    content
  );

  return wrapper;
}

function SectionTitleContent({ title, subtitle, highlightWords, color }) {
  if (!title) {
    return <></>;
  }
  const splitTitle = title.split(" ");
  const highlightedWords = highlightWords
    ? splitTitle
        .slice(splitTitle.length - highlightWords, splitTitle.length)
        .join(" ")
    : splitTitle.slice(1, splitTitle.length).join(" ");

  const normalWords = highlightWords
    ? splitTitle.slice(0, splitTitle.length - highlightWords).join(" ")
    : splitTitle[0];

  return (
    <span
      className={`${styles.titleContainer}`}
      style={{ color: color ?? "inherit" }}
    >
      <span className={`${styles.title}`}>
        <span>{normalWords}&nbsp;</span>

        {highlightedWords.split(" ").map((word, index) => (
          <span key={`${word}-${index}`} className={`${styles.highlight}`}>
            {word}&nbsp;
          </span>
        ))}
      </span>
      {subtitle && (
        <p className={`${styles.subtitle}`} style={{ color: color }}>
          {subtitle}
        </p>
      )}
    </span>
  );
}
