import Image from "next/image";
import Link from "next/link";
import Markdown from "markdown-to-jsx";
import DOMPurify from "isomorphic-dompurify";

import SectionTitle from "components/typography/SectionTitle/SectionTitle";
import useWindowSize from "lib/hooks/useWindowSize";
import styles from "./OurStory.module.scss";

const OurStory = ({ home }) => {
  // console.log(home);
  const { width } = useWindowSize();
  return (
    <div className={`variable-container ${styles.ourStoryContainer}`}>
      <div className={`${styles.column1}`}>
        <h2 className={`${styles.title} ${styles.desktopTitle}`}>
          <SectionTitle title="Our Story" />
        </h2>
        <div className={`${styles.text}`}>
          <Markdown options={{ forceBlock: true }}>
            {DOMPurify.sanitize(home?.ourStoryDescription || "")}
          </Markdown>
        </div>
        <Link
          href="/about"
          title="Read More about Our Story"
          aria-label="Read More about Our Story"
          className={`${styles.readMoreLink}`}
        >
          Read more about our story
          <span className={`${styles.arrow}`}>
            <Image
              src="/assets/img/icon/awrro-black.svg"
              alt="Read more icon"
              width="30"
              height="14"
            />
          </span>
        </Link>
      </div>
      <div className={`${styles.column2}`}>
        {width < 992 && (
          <h2 className={`${styles.title}`}>
            <SectionTitle title="Our Story" />
          </h2>
        )}
        <div className={`${styles.slider}`}>
          <Image
            src={home.ourStorySlider[0].sliderImage.url}
            alt={
              home.ourStorySlider[0].sliderImage.sliderImageAlt ??
              "Our story image"
            }
            width="521"
            height="521"
          />
        </div>
      </div>
    </div>
  );
};

export default OurStory;
